<template>
  <div>
    <div :class="`dropdown ${buttonHoverClass}`">
      <div id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="cursor-pointer d-inline-flex align-items-center gap-2">
        {{ title }} <div class="round-icon"> <Icon icon="material-symbols-light:more-vert" class="text-lg" /></div>
      </div>
      <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps([ "title", "buttonHover" ]);
const buttonHoverClass = computed(() => {
  if (props.buttonHover) return "dropdown-hover";
  else return "";
});
</script>
